<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
    @click="onClickCallback"
  >
    <div
      v-dompurify-html="value"
      class="content_display"
      data-type="variables"
      :style="style"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTextVariablesFormatted',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone)
      const defaultValue = builder.getLocalization(this.cell, 'preset_value') || ''
      const variables = this.data.variables

      if (defaultValue) {
        try {
          // @todo Muss jedenfalls nach LTH angepasst werden, da er immer dumm
          // @todo alles neu baut :/
          value = defaultValue
          const matches = defaultValue.match(/@[a-z_0-9]+@/gi) || []
          for (let variable, i = 0; (variable = matches[i++]);) {
            variable = variable.toLowerCase()
            builder.addVariableCellMapping(variable, index, this.cell)
            const regex = new RegExp(variable, 'gi')
            value = value.replace(regex, variables[variable] || '')
          }
        } catch (ex) {
          // Keine Aktion nötig
        }
      }
      return value
    }
  },
  methods: {
    onClickCallback() {
      if (!this.cell.settings.on_click_callback) {
        return
      }
      const builder = this.data.ChecklistBuilder
      const onCellClicked = builder.getSetting('ON_CELL_CLICKED')
      if (typeof onCellClicked !== 'function') {
        return
      }
      onCellClicked(this.cell)
    }
  }
}
</script>
